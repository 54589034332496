html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%;
}

.quarter {
  padding: 0px;
}

.placeholder {
  -webkit-filter: grayscale(0.3) brightness(60%);
}

.card-img-top {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #002f4b, #dc4225);
    opacity: .6;
  }
}

@media(min-width:780px) {
  .quarter {
    padding: 1px;
    width: 33.3%;
    height: 100%;
    float: left;
  }
}

.text-white {
  color: #eee;
}

.contents {
  height: 33.5%;
  width: 100%;
}

.playicon {
  position: absolute;
  top: calc(50% - 60px);
  width: 120px;
  cursor: pointer;
  left: calc(50% - 60px);
}

@media(max-width:780px) {
  .playicon {
    top: calc(50% - 30px);
    width: 60px;
    left: calc(50% - 30px);
  }
}